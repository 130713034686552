import React from "react";
import PropTypes from "prop-types";
require("core-js/fn/array/from");

import { getScreenWidth, getScreenHeight } from "../../utils/helpers";

import { FaHome } from "react-icons/fa/";
import { FaSearch } from "react-icons/fa/";
import { FaEnvelope } from "react-icons/fa/";
import { FaTag } from "react-icons/fa/";

import Item from "./Item";
// import Expand from "./Expand";
import ExpandFromTop from "./ExpandFromTop";

class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.itemList = React.createRef();

    const pages = props.pages.map(page => ({
      to: page.node.fields.slug,
      label: page.node.frontmatter.menuTitle
        ? page.node.frontmatter.menuTitle
        : page.node.frontmatter.title,
      fluid: page.node.frontmatter.cover ? page.node.frontmatter.cover.children[0].fluid : null
    }));

    this.items = [
      // { to: "/", label: "Home", icon: FaHome, cover: null },
      ...pages
      // { to: "/artsandculture/", label: "Art & Culture", cover: null },
      // { to: "/category/", label: "Categories", icon: FaTag , cover: null},
      // { to: "/search/", label: "Search", icon: FaSearch, cover: null },
      // { to: "/contact/", label: "Talk To Us", icon: FaEnvelope, cover: null }
    ];

    this.items.map(item => {
      item.to = item.label == "Home" ? "/" : item.to;
      item.to = item.label == "Inspire Us" ? "/inspireus/" : item.to;
      item.to = item.label == "Search" ? "/search/" : item.to;
      item.to = item.label == "Talk to Us" ? "/contact/" : item.to;
    });
    console.log(this.items);

    this.renderedItems = []; // will contain references to rendered DOM elements of menu
  }

  state = {
    open: false,
    hiddenItems: []
  };

  static propTypes = {
    path: PropTypes.string.isRequired,
    fixed: PropTypes.bool.isRequired,
    screenWidth: PropTypes.number.isRequired,
    fontLoaded: PropTypes.bool.isRequired,
    pages: PropTypes.array.isRequired,
    theme: PropTypes.object.isRequired
  };

  componentDidMount() {
    this.renderedItems = this.getRenderedItems();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.path !== prevProps.path ||
      this.props.fixed !== prevProps.fixed ||
      this.props.screenWidth !== prevProps.screenWidth ||
      this.props.fontLoaded !== prevProps.fontLoaded
    ) {
      if (this.props.path !== prevProps.path) {
        this.closeMenu();
      }
      this.hideOverflowedMenuItems();
    }
  }

  getRenderedItems = () => {
    const itemList = this.itemList.current;
    return Array.from(itemList.children);
  };

  getHomepage = () => {
    const homepage = this.props.path === "/" ? "homepage" : "";
    return ` ${homepage}`;
  };

  hideOverflowedMenuItems = () => {
    // const PADDING_AND_SPACE_FOR_MORELINK = this.props.screenWidth >= 1024 ? 60 : 0;
    const PADDING_AND_SPACE_FOR_MORELINK = 60;

    const itemsContainer = this.itemList.current;
    const maxWidth = itemsContainer.offsetWidth - PADDING_AND_SPACE_FOR_MORELINK;

    this.setState({ hiddenItems: [] }); // clears previous state

    const menu = this.renderedItems.reduce(
      (result, item) => {
        item.classList.add("item");
        item.classList.remove("hideItem");

        const currentCumulativeWidth = result.cumulativeWidth + item.offsetWidth;
        result.cumulativeWidth = currentCumulativeWidth;

        if (!item.classList.contains("more")) {
          //&& currentCumulativeWidth > maxWidth) {
          const link = item.querySelector("a");

          item.classList.add("hideItem");
          item.classList.remove("item");
          result.hiddenItems.push({
            to: link.getAttribute("data-slug"),
            label: link.text
          });
        }
        return result;
      },
      { visibleItems: [], cumulativeWidth: 0, hiddenItems: [] }
    );

    this.setState(prevState => ({ hiddenItems: menu.hiddenItems }));
  };

  toggleMenu = e => {
    e.preventDefault();

    if (this.props.screenWidth < 1024) {
      this.renderedItems.map(item => {
        const oldClass = this.state.open ? "showItem" : "hideItem";
        const newClass = this.state.open ? "hideItem" : "showItem";

        if (item.classList.contains(oldClass)) {
          item.classList.add(newClass);
          item.classList.remove(oldClass);
        }
      });
    }

    this.setState(prevState => ({ open: !prevState.open }));
  };

  closeMenu = e => {
    //e.preventDefault();

    if (this.state.open) {
      this.setState({ open: false });
      if (this.props.screenWidth < 0) {
        this.renderedItems.map(item => {
          if (item.classList.contains("showItem")) {
            item.classList.add("hideItem");
            item.classList.remove("item");
          }
        });
      }
    }
  };

  render() {
    const { screenWidth, theme } = this.props;
    const { open } = this.state;

    const screenHeight = getScreenHeight();
    return (
      <React.Fragment>
        <nav className={`menu ${open ? "open" : ""} ${this.getHomepage()}`} rel="js-menu">
          <ul className="itemList" ref={this.itemList}>
            {this.items.map(item => (
              <Item item={item} key={item.label} icon={item.icon} theme={theme} />
            ))}
          </ul>
          {this.state.hiddenItems.length > 0 && (
            <ExpandFromTop onClick={this.toggleMenu} theme={theme} className="menuButton" />
          )}
          {open && screenWidth >= 0 && (
            <ul className="hiddenItemList">
              {this.items.map(item => (
                <Item item={item} key={item.label} hiddenItem theme={theme} />
              ))}
            </ul>
          )}
        </nav>

        {/* --- STYLES --- */}
        <style jsx>{`
          .menu {
            align-items: center;
            background: ${theme.color.neutral.black} ;
            top: 0;
            display: flex;
            flex-grow: 1;
            left: 0;
            max-height: ${open ? "100px" : "-100px"};
            padding: ${theme.space.inset.xs};
            position: fixed;
            width: 100%;
            z-index: 1;
            transition: all ${theme.time.duration.default};
            border: ${theme.color.brand.primary};

            /*&.homepage {
              position: relative;
            }*/

            &:after {
              content: "";
              background: ${theme.background.color.primary};
              z-index: 10;
              top: -10px;
              right: -1px;
              width: 44px;
              height: 10px;
              position: absolute;
              border-left: 1px solid ${theme.line.color};
              border-right: 1px solid ${theme.line.color};
            }

          }

          .itemList {
            display: none; /* flex;*/
            flex-wrap: wrap;
            justify-content: center;
            list-style: none;
            margin: 0;
            padding: 0; /* 0 ${theme.space.s}; */
            position: relative;
            width: 100%;
            cursor: pointer;

          }


          .menu {
            border-top: none;
            background: transparent;
            display: flex;
            position: absolute;
            justify-content: flex-end;
            padding-left: 50px;
            transition: none;

            /*&.homepage {
              position: relative;
            }*/
          }

          .itemList {
            justify-content: flex-end;
            padding: 0;
          }

          .hiddenItemList {
            list-style: none;
            margin: 0 ;
            position: absolute;

            background: ${theme.color.brand.dark} ;
            border: 1px solid ${theme.line.color};
            top: 0;
            left: 0;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            padding: ${theme.space.m};
            border-radius: ${theme.size.radius.small};
            border-top-right-radius: 0;
            width: 100%;
            left: 0;
            line-height: 3em;
            position: absolute;
            width:100%;
            height: ${screenHeight}px;

            -webkit-animation-name: fadeInMenu; 
            -webkit-animation-duration: 1s; 
            animation-name: fadeInMenu;
            animation-duration: 1s;



            &:after {
              content: "";
              background: ${theme.background.color.primary};
              z-index: 10;
              top: -10px;
              right: -1px;
              width: 44px;
              height: 10px;
              position: absolute;
              border-left: 1px solid ${theme.line.color};
              border-right: 1px solid ${theme.line.color};
            }

            :global(.homepage):not(.fixed) & {
              border: 1px solid transparent;
              background: ${theme.background.color.dark} ;
              top: 0px;


              &:after {
                top: -11px;
                border-left: 1px solid transparent;
                border-right: 1px solid transparent;
                background: ${theme.background.color.dark} ;
              }
            }

            :global(.fixed) & {
              top: 0px;
            }



          }


          
          @-webkit-keyframes fadeInMenu {
            from {opacity: 0;}
            to {opacity: 1;}
          }
          
          @keyframes fadeInMenu {
            from {opacity: 0;}
            to {opacity: 1;}
          }


          @below mobile {
            .hiddenItemList {
              font-size: ${theme.font.size.m};
            }
          }

          @between-from mobile tablet {
            .hiddenItemList {
              font-size: ${theme.font.size.m};
            }
          }

          @between-from tablet desktop {
            .hiddenItemList {
              font-size: ${theme.font.size.xxl};
            }
          }

          @from-width  desktop {
            .hiddenItemList {
              font-size: ${theme.font.size.xxl};
            }
          }

        `}</style>
      </React.Fragment>
    );
  }
}

export default Menu;
