module.exports = {
  siteTitle: "Society of Eritrean Women", // <title>
  shortSiteTitle: "Society of Eritrean Women", // <title> ending for posts and pages
  siteDescription: "I BELONG DEEPLY TO MYSELF. ~WARSAN SHIRE",
  siteUrl: "https://www.societyoferitreanwomeen.com",
  // pathPrefix: "",
  siteImage: "preview.jpg",
  siteLanguage: "en",

  /* author */
  authorName: "SEW",
  authorTwitterAccount: "@SEWSocialScene",

  /* info */
  headerTitle: "",
  headerSubTitle: "",

  /* manifest.json */
  manifestName: "Society of Eritrean Women",
  manifestShortName: "SEW-Eritrea", // max 12 characters
  manifestStartUrl: "/index.html",
  manifestBackgroundColor: "#b16f00",
  manifestThemeColor: "#b16f00",
  manifestDisplay: "standalone",

  // gravatar
  // Use your Gravatar image. If empty then will use src/images/jpg/avatar.jpg
  // Replace your email adress with md5-code.
  // Example https://www.gravatar.com/avatar/g.strainovic@gmail.com ->
  // gravatarImgMd5: "https://www.gravatar.com/avatar/1db853e4df386e8f699e4b35505dd8c6",
  gravatarImgMd5: "/icons/icon-512x512.png",

  // social
  authorSocialLinks: [
    { name: "twitter", url: "https://twitter.com/SEWSocialScene" },
    { name: "instagram", url: "https://www.instagram.com/SEWSocialScene/" }
  ]
};
