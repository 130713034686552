import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

import Img from "gatsby-image";

import logo from "../../images/png/logo_SEW_x192_wht.png";

const Item = props => {
  const { theme, item: { label, to, fluid, icon: Icon } = {}, onClick } = props;

  // console.log(props);
  // console.log(fluid.src);

  return (
    <React.Fragment>
      <div className="menuItemWithImage">
        <li className={"hiddenItem" in props ? "hiddenItem" : "item"} key={label}>
          <Link
            to={to}
            className={"hiddenItem" in props ? "inHiddenItem" : ""}
            onClick={onClick}
            data-slug={to}
          >
            {Icon && <Icon />} {label}
          </Link>
        </li>
        <div className="menuItemImage-container">
          {fluid != null ? (
            <div className="gatsby-image-outer-wrapper">
              <Img style={{}} fluid={fluid} />
            </div>
          ) : null}
        </div>
      </div>
      {/* --- STYLES --- */}
      <style jsx>{`
        .item,
        .showItem {
          background: transparent;
          transition: all ${theme.time.duration.default};
          display: flex;
          align-items: center;

          :global(a) {
            padding: ${theme.space.inset.s};
            display: flex;
            align-items: center;
            font-weight: ${theme.font.weight.bold};
          }

          :global(svg) {
            margin: 0 ${theme.space.inset.xs} 0 0;
            opacity: 0.3;
          }
        }

        :global(.itemList .hideItem) {
          display: none;
        }

        .menuItemWithImage {
          .menuItemImage-container {
            position: absolute;
            top: 0%;
            right: 0%;
            width: 50%;
            margin-bottom: 00px;
            display: none;
          }

          .hiddenItem {
            transition-timing-function: ease-out;
            transition: 0.3s;
            -webkit-transition: 0.3s;
            -moz-transition: 0.3s;
            position: relative;
            left: 0;
          }

          & :hover {
            .menuItemImage-container {
              display: block;
            }

            .hiddenItem {
              left: 5px;
              /* font-style: oblique; */

              & :before {
                content: "♢";
                font-size: smaller;
                transform: scaleX(0);
                transform-origin: left center;
                transition: all 0.3s ease-out;
              }
            }
          }

          .menuItemImage-container {
            display: none;
          }
        }

        @-webkit-keyframes slideLeft {
          from {
            left: 0;
          }
          to {
            left: 20;
          }
        }

        @keyframes slideLeft {
          from {
            left: 0;
          }
          to {
            left: 20;
          }
        }

        @below mobile {
          .hiddenItem {
            text-align: left;
            padding: ${theme.space.xs} ${theme.space.s};

            & :global(a.inHiddenItem) {
              color: ${theme.color.neutral.white};
              &:hover {
                color: ${theme.color.brand.primary};
              }
            }
          }
        }

        @between-from mobile tablet {
          .hiddenItem {
            text-align: left;
            padding: ${theme.space.xs} ${theme.space.m};

            & :global(a.inHiddenItem) {
              color: ${theme.color.neutral.white};
              &:hover {
                color: ${theme.color.brand.primary};
              }
            }
          }
        }

        @between-from tablet desktop {
          .hiddenItem {
            text-align: left;
            padding: ${theme.space.xs} ${theme.space.l};

            & :global(a.inHiddenItem) {
              color: ${theme.color.neutral.white};
              &:hover {
                color: ${theme.color.brand.primary};
              }
            }
          }
        }

        @from-width desktop {
          .hiddenItem {
            text-align: left;
            padding: ${theme.space.xs} ${theme.space.xl};

            & :global(a.inHiddenItem) {
              color: ${theme.color.neutral.white};
              &:hover {
                color: ${theme.color.brand.primary};
              }
            }
          }
          .item {
            :global(a) {
              color: ${theme.text.color.primary};
              padding: ${theme.space.inset.s};
              transition: all ${theme.time.duration.default};
              border-radius: ${theme.size.radius.small};
            }

            :global(.homepage):not(.fixed) & :global(a) {
              color: ${theme.color.neutral.white};
            }

            :global(a:hover) {
              color: ${theme.color.brand.primary};
              background: color(white alpha(-60%));
            }

            :global(svg) {
              transition: all ${theme.time.duration.default};
            }

            &:hover :global(svg) {
              fill: ${theme.color.brand.primary};
              opacity: 1;

              :global(.hero) & :global(svg) {
                fill: green;
              }
            }
          }

          .showItem {
            display: none;
          }

          .hiddenItem {
            text-align: left;
            padding: ${theme.space.xs} ${theme.space.xl};

            & :global(a.inHiddenItem) {
              color: ${theme.color.neutral.white};
              &:hover,
              &:before {
                color: ${theme.color.brand.primary};
              }
            }
          }
        }
      `}</style>
    </React.Fragment>
  );
};

Item.propTypes = {
  item: PropTypes.object,
  hidden: PropTypes.bool,
  onClick: PropTypes.func,
  icon: PropTypes.func,
  theme: PropTypes.object.isRequired
};

export default Item;
