import { FaBars } from "react-icons/fa/";
import PropTypes from "prop-types";
import React from "react";

const ExpandFromTop = props => {
  const { onClick, theme } = props;

  return (
    <React.Fragment>
      <button className="more" to="#" onClick={onClick} aria-label="expand">
        <FaBars size={30} color={theme.color.brand.secondary} />
      </button>

      {/* --- STYLES --- */}
      <style jsx>{`
        .more {
          cursor: pointer;
          flex-shrink: 0;
          flex-grow: 0;
          width: 44px;
          height: 38px;
          background: transparent;
          margin-left: 10px;
          border-radius: ${theme.size.radius.small};
          border: 1px solid ${theme.line.color};
          display: flex;
          transition: background-color ${theme.time.duration.default};
          justify-content: center;
          align-items: center;
          padding: 0;
          z-index: 1;

          &:focus,
          &:hover {
            outline: none;
          }

          :global(svg) {
            transition: all ${theme.time.duration.default};
          }

          :global(.homepage) & {
            border: 1px solid transparent;
            background-color: color(white alpha(-90%));

            &:hover {
              background-color: color(white alpha(-60%));
            }
          }

          :global(.open) & {
            background-color: color(white alpha(-10%));

            &:hover {
              background-color: color(white alpha(-10%));
            }

            :global(svg) {
              transform: rotate(0deg);
            }
          }

          :global(.fixed) & {
            border: 1px solid ${theme.line.color};
            /*height: 30px;*/
          }
        }

        @from-width tablet {
          .more,
          .more svg {
            width: ${theme.font.size.superLarge};
            height: ${theme.font.size.superLarge};
          }
        }

        @from-width desktop {
          .more,
          .more svg {
            width: ${theme.font.size.superLarge};
            height: ${theme.font.size.superLarge};
          }
        }
      `}</style>
    </React.Fragment>
  );
};

ExpandFromTop.propTypes = {
  onClick: PropTypes.func,
  theme: PropTypes.object.isRequired
};

export default ExpandFromTop;
